<template>
	<ul
		class="navbar"
		v-on:click="scrollToTable"
	>
		<li
			v-for="navItem in items"
			:key="navItem.name"
			:class="{navbar__item_active: isActive(navItem.name), navbar__item: true}"
			:data-target="navItem.target"
			@click="setActive(navItem)"
		>
			{{ navItem.name }}
		</li>
	</ul>
</template>

<script>
export default {
	props: ['data', 'active'],
	data()
	{
		return {
			items: this.data,
			activeItem: this.active,
		}
	},
	methods: {
		isActive(item)
		{
			return this.activeItem === item;
		},
		setActive(item)
		{
			this.activeItem = item.name;
			this.$emit('changeActive', item.target)
		},
		scrollToTable(event)
		{
			const table = document.querySelector(event.target.dataset.target);

			if (table)
			{
				table.scrollIntoView({block: "center", behavior: 'smooth'});
			}
		},
	}
}
</script>

<style lang="scss">
$gray: #979797;
$black: #3D4248;
$lightgray: #F0F0F0;
$green: #40AE49;

.navbar
{
	list-style: none;
	display: flex;
	margin-bottom: 44px;
	position: relative;
	font-family: Roboto, PT Sans, sans-serif;

	&:after
	{
		content: '';
		position: absolute;
		display: block;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		border-bottom: 2px solid $lightgray;
	}

	&__item
	{
		font-size: 16px;
		font-weight: 700;
		color: $black;
		cursor: pointer;
		padding-bottom: 10px;
		z-index: 1;

		&:not(:last-child)
		{
			margin-right: 24px;
		}

		&_active
		{
			color: $green;
			border-bottom: 2px solid;
		}
	}
}
</style>
